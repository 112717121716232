import React, { useState, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { SurveyWrapper, OptionLabel, SurveyTitle, BottomButtonWrapper } from '../Style/SurveyStyle';
import { GradientText } from '../Style/GlobalStyle';
import { useTranslation, Trans } from 'react-i18next';

const surveyOptions = [
  'news',
  'it',
  'realEstate',
  'health',
  'entertainment',
  'shopping',
  'sports',
  'culture',
  'community'
];

const Survey = ({ onSubmit, onPreviousStep, surveyData }) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState(surveyData.selectedOptions);
  const [customOption, setCustomOption] = useState(surveyData.customOption);
  const [isCustomSelected, setIsCustomSelected] = useState(!!surveyData.customOption);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const customInputRef = useRef(null);
  const formRef = useRef(null);
  const isScrollingRef = useRef(false);

  useEffect(() => {
    setSelectedOptions(surveyData.selectedOptions);
    setCustomOption(surveyData.customOption);
    setIsCustomSelected(!!surveyData.customOption);
  }, [surveyData]);

  useEffect(() => {
    const handleFocus = () => {
      setIsInputFocused(true);
      customInputRef.current.scrollIntoView({ block: 'center' }); // smooth 옵션 제거
      setTimeout(() => {
        isScrollingRef.current = false;
      }, 300); // 스크롤 완료 후 키보드가 올라오도록 약간의 지연 시간을 추가합니다.
    };

    const handleBlur = () => {
      setIsInputFocused(false);
      enableBodyScroll(formRef.current);
    };

    const handleScroll = () => {
      if (isScrollingRef.current) return;
      if (document.activeElement === customInputRef.current) {
        customInputRef.current.blur();
      }
    };

    if (customInputRef.current) {
      customInputRef.current.addEventListener('focus', handleFocus);
      customInputRef.current.addEventListener('blur', handleBlur);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (customInputRef.current) {
        customInputRef.current.removeEventListener('focus', handleFocus);
        customInputRef.current.removeEventListener('blur', handleBlur);
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((o) => o !== option) : [...prev, option]
    );
  };

  const handleCustomChange = (e) => {
    const value = e.target.value;
    setCustomOption(value);
    setIsCustomSelected(value.length > 0);
  };

  const handleCustomClick = () => setIsCustomSelected(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ selectedOptions, customOption });
  };

  return (
    <SurveyWrapper ref={formRef}>
      <SurveyTitle>
        <Trans i18nKey="survey.title" components={{ 1: <GradientText /> }} />
      </SurveyTitle>
      <form onSubmit={handleSubmit}>
        {surveyOptions.map((option) => (
          <OptionLabel key={option} selected={selectedOptions.includes(option)}>
            {t(`survey.options.${option}`)}
            <input
              type="checkbox"
              checked={selectedOptions.includes(option)}
              onChange={() => handleChange(option)}
            />
            <span className="custom-checkbox"></span>
          </OptionLabel>
        ))}
        <OptionLabel selected={isCustomSelected} onClick={handleCustomClick}>
          <input
            type="text"
            value={customOption}
            onChange={handleCustomChange}
            placeholder={t('survey.customPlaceholder')}
            onClick={(e) => e.stopPropagation()} // 부모의 onClick 이벤트를 막기 위해
            ref={customInputRef}
            onFocus={() => { isScrollingRef.current = true; }} // 포커스가 발생할 때 스크롤 플래그 설정
          />
          <input
            type="checkbox"
            checked={isCustomSelected}
            readOnly
            style={{ display: 'none' }}
          />
          <span className="custom-checkbox"></span>
        </OptionLabel>
        <BottomButtonWrapper>
          <button type="submit">{t('survey.submitButton')}</button>
        </BottomButtonWrapper>
      </form>
    </SurveyWrapper>
  );
};

export default Survey;